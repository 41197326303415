<template>
  <!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
  <div class="qr-codes">
    QrCodes Screen<br /><br />

    <router-link tag="div" :to="{ name: 'Home' }" v-slot="{ navigate }">
      <ui-button button="cta" icon="world" label="Go back home" @click="navigate" />
    </router-link>
  </div>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'

export default {
  name: 'QrCodes',
  components: {
    UiButton,
  },
}
</script>
